<template>
  <footer
    class="footer custom-footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="#/impressum">
              Impressum
            </a>
          </li>
          <li>
            <a href="#/dataPrivacy">
              Datenschutz
            </a>
          </li>
          <li>
            <a href="#/agb">
              AGB
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, Designed by
        Fabian Zeller
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
.custom-footer {
  height: 7vh;
}

@media (min-width: 375px) {
  .custom-footer {
    height: 12vh;
  }
}

@media (min-width: 512px) {
  .custom-footer {
    height: 7vh;
  }
}
</style>
