<template>
  <div class="impressumPage">
    <div class="section section-about-us first-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto text-center">
            <h2 class="title">Fallbeispiele</h2>
            <div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="hybrid-tab" data-bs-toggle="tab" data-bs-target="#hybrid" type="button" role="tab" aria-controls="hybrid">Hybrid - Abutment</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="color-tab" data-bs-toggle="tab" data-bs-target="#color" type="button" role="tab" aria-controls="color">Individuelle Zahnfarbe</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="stege-tab" data-bs-toggle="tab" data-bs-target="#stege" type="button" role="tab" aria-controls="stege">Stege</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="veneer11-tab" data-bs-toggle="tab" data-bs-target="#veneer11" type="button" role="tab" aria-controls="veneer11">Veneer 11</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="veneer13-tab" data-bs-toggle="tab" data-bs-target="#veneer13" type="button" role="tab" aria-controls="veneer13">Veneer 13-23</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="implant-tab" data-bs-toggle="tab" data-bs-target="#implant" type="button" role="tab" aria-controls="implant">Implantatkrone Zahn 12</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="gold-tab" data-bs-toggle="tab" data-bs-target="#gold" type="button" role="tab" aria-controls="gold">Goldteilkronen Inlays</button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade" id="hybrid" role="tabpanel" aria-labelledby="hybrid-tab">
                  <div class="container">
                    <h2>Stabil und schön</h2>
                    <p>Implantate können besonders in sichtbaren Bereichen mit sogenannten Hybrid-Aufbauten versorgt werden.
                      Diese vereinen die Stabilität und Haltbarkeit von Titan mit der Ästhetik von Vollkeramik.</p>
                    <div class="row justify-content-center">
                      <div class="col-10">
                        <el-carousel :indicatorPosition="'none'" :arrow="'always'" :autoplay="false" height="400px">
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/hybrid/Hybrid-1.jpeg" alt="First slide" />
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/hybrid/Hybrid-2.jpeg" alt="Second slide" />
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/hybrid/Hybrid-3.jpg" alt="Third slide" />
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/hybrid/Hybrid-4.jpeg" alt="Third slide" />
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/hybrid/Hybrid-5.jpg" alt="Third slide" />
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/hybrid/Hybrid-6.jpg" alt="Third slide" />
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/hybrid/Hybrid-7.jpg" alt="Third slide" />
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="color" role="tabpanel" aria-labelledby="color-tab">
                  <div class="container">
                    <h2>Warum individuelle Zahnfarbe</h2>
                    <p>Zähne sind lebende Teile unseres Körpers. Sie bestehen aus Zahnbein und Zahnschmelz, das härteste Material, das unser Körper erzeugen kann!!
                      Nun ist dieser Zahnschmelz zudem sehr transparent, bzw. das Zahnbein ist transluzent. Daraus entsteht immer eine dreidimensionaler Farbeindruck,
                      welcher durch die tieferliegenden Farbnuancen und den darüberliegenden Schichten zu einer komplexen Gesamtfarbe wird.
                      Sie wissen sicher, wie schwierig es ist, bei einer einfachen Oberflächenfarbe, wie zB. einer Tapete, eine Farbbestimmung durch eine RAL-Farbkarte festzulegen.
                      Der gezeigte Fall wäre ohne das Abmischen einer individuellen Zahnfarbe nicht befriedigend lösbar!!</p>
                      <br>
                      <p>„In der Zahntechnik beginnt die wahre Kunst da, wo man sie nicht mehr sieht“ <sub>Gernot Goetz</sub></p>
                    <div class="row justify-content-center">
                      <div class="col-10">
                        <el-carousel :indicatorPosition="'none'" :arrow="'always'" :autoplay="false" height="400px">
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/zahnfarbe/Zahnfarbe-1.jpeg" alt="First slide" />
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/zahnfarbe/Zahnfarbe-2.jpeg" alt="Second slide" />
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/zahnfarbe/Zahnfarbe-3.jpeg" alt="Third slide" />
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="stege" role="tabpanel" aria-labelledby="stege-tab">
                  <div class="container">
                    <h2>Stegprothesen</h2>
                    <p>In der Regel plant man eine abnehmbare Prothese bei größerem Gewebe- und Knochenverlust, um mit einem vestibulärem Lippenschild, die Lippe zu unterstützen.
                      Oft werden Implantate mit einem Steg verbunden, damit wird die Primärstabilität der Implantate erhöht und man erhält auch bei ungünstigen Knochenverhältnissen eine optimale Kraftverteilung der Kaukräfte.
                      Da bei idealer Gestaltung der Steg genau unter den Prothesenzähnen sitzt, ist dieser bei den von uns hergestellten Arbeiten, weder zu sehen noch zu spüren.
                      Zusätzlich kann eine Stegkonstruktion mit einem Riegel oder anderen Halteelementen versehen werden, um einen absolut festsitzenden Zahnersatz zu bekommen.
                    <div class="row justify-content-center">
                      <div class="col-10">
                        <el-carousel :indicatorPosition="'none'" :arrow="'always'" :autoplay="false" height="400px" class="container">
                          <el-carousel-item class="center-image">
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/stege/Stege-1.jpeg" alt="First slide" />
                              <figcaption class="image-caption">überprüfen der Platzverhältnisse auf dem Modell</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/stege/Stege-2.jpeg" alt="Second slide" />
                              <figcaption class="image-caption">überprüfen der Modellpräzision...</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/stege/Stege-3.jpg" alt="Third slide" />
                              <figcaption class="image-caption">mit Hilfe eines Übertragungsrahmens...</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/stege/Stege-4.jpg" alt="Third slide" />
                              <figcaption class="image-caption">der zur Korrektur und...</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/stege/Stege-5.jpg" alt="Third slide" />
                              <figcaption class="image-caption">auch der Bissfestlegung dient.</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/stege/Stege-6.jpeg" alt="Third slide" />
                              <figcaption class="image-caption">Fertige Arbeit auf dem Modell</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/stege/Stege-7.jpg" alt="Third slide" />
                              <figcaption class="image-caption">Oberkiefer in Situ...</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/stege/Stege-8.jpg" alt="Third slide" />
                              <figcaption class="image-caption">Unterkiefer in Situ.</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/stege/Stege-9.jpg" alt="Third slide" />
                              <figcaption class="image-caption">Ein Höchstmaß an Präzision auf engstem Raum!</figcaption>
                            </figure>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="veneer11" role="tabpanel" aria-labelledby="veneer11-tab">
                  <div class="container">
                    <h2>Veneer-11</h2>
                    <p>Eine junge Frau kommt nach einem Unfall, mit gebrochenem Schneiderzahn (Zahn 11), in die Praxis. Der Behandler entscheidet sich für eine sehr substanzschonende Teilpräparation.
                      Die Schwierigkeit bestand in der quer, durch die Labialfläche verlaufende Präparationsgrenze und der sehr transparenten Schneidekante.</p>
                    <div class="row justify-content-center">
                      <div class="col-10">
                        <el-carousel :indicatorPosition="'none'" :arrow="'always'" :autoplay="false" height="400px" class="container">
                          <el-carousel-item class="center-image">
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/veneer11/Veneer-1.jpeg" alt="First slide" />
                              <figcaption class="image-caption">Teilpräparation nach Fraktur</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/veneer11/Veneer-2.jpeg" alt="Second slide" />
                              <figcaption class="image-caption">Die Rohbrandeinprobe im Labor</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/veneer11/Veneer-3.jpg" alt="Third slide" />
                              <figcaption class="image-caption">Das fertige Veneer vom Zahnarzt zementiert</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/veneer11/Veneer-4.jpg" alt="Third slide" />
                              <figcaption class="image-caption">Nach 4 Jahren in Situ</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/veneer11/Veneer-5.jpg" alt="Third slide" />
                              <figcaption class="image-caption">Farbe, Form und Oberfläche in Harmonie mit den natürlichen Zähnen</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/veneer11/Veneer-6.jpg" alt="Third slide" />
                              <figcaption class="image-caption">Aesthetik pur...</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/veneer11/Veneer-7.jpg" alt="Third slide" />
                              <figcaption class="image-caption">Welches ist der echte Zahn?</figcaption>
                            </figure>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="veneer13" role="tabpanel" aria-labelledby="veneer13-tab">
                  <div class="container">
                    <h2>Veneer 13-23</h2>
                    <p>
                      Dieser Fall zeigt einen Patienten mit nicht vollständig entwickelten Schneidezähnen.
                      Lediglich die gebrochene Schneidekante an Zahn 21 wurde präpariert.
                      Die restlichen Zähne wurden Non-invasiv (ohne zu beschleifen) mit Veneer's versorgt.
                    </p>
                    <div class="row justify-content-center">
                      <div class="col-10">
                        <el-carousel :indicatorPosition="'none'" :arrow="'always'" :autoplay="false" height="400px">
                          <el-carousel-item class="center-image">
                            <img src="img/zahnwerkstatt/fallbeispiele/veneer13-23/Veneer1323-1.jpeg" alt="First slide" />
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/veneer13-23/Veneer1323-2.jpeg" alt="Second slide" />
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade show active" id="implant" role="tabpanel" aria-labelledby="implant-tab">
                  <div class="container">
                    <h2>Implantatkrone Zahn 12</h2>
                    <p>Der verlorengegangene Schneidezahn 12 wurde vom Zahnarzt durch ein Implantat ersetzt.
                      Auf diesem Titanimplantat fertigten wir eine Vollkeramikkrone aus e.max®. Bei Frontzahnkronen ist es auch sehr wichtig, die richtige Zahnfarbe zu treffen.
                      Ein weiterer entscheidender Faktor ist das Kopieren von Zahnform und Oberfläche der Nachbarzähne. Auf den Bildern ist zu sehen, dass sich je nach Betrachtungswinkel auch die Oberflächenreflektion ändert.
                    </p>
                    <div class="row justify-content-center">
                      <div class="col-10">
                        <el-carousel :indicatorPosition="'none'" :arrow="'always'" :autoplay="false" height="400px">
                          <el-carousel-item class="center-image">
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/implantkrone/01_Impl.jpeg" alt="First slide" />
                              <figcaption class="image-caption">Situation vor Abformung/ Abdrucknahme</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/implantkrone/02_Impl.jpg" alt="Second slide" />
                              <figcaption class="image-caption">Zahnfabbestimmung im Labor</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/implantkrone/03_Impl.jpg" alt="Third slide" />
                              <figcaption class="image-caption">erster Farbvergleich der Rohkrone</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/implantkrone/04_Impl.jpeg" alt="Third slide" />
                              <figcaption class="image-caption">Oberflächengestaltung/-überprüfung auf dem Modell</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/implantkrone/05_Impl.jpg" alt="Third slide" />
                              <figcaption class="image-caption">Krone im Mund des Patienten</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/implantkrone/06_Impl.jpg" alt="Third slide" />
                              <figcaption class="image-caption">Form und Farbe in Situ</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/implantkrone/07_Impl.jpg" alt="Third slide" />
                              <figcaption class="image-caption">Form und Farbe in Situ</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/implantkrone/08_Impl.jpg" alt="Third slide" />
                              <figcaption class="image-caption">auch die Oberfläche entspricht den Nachbarzähnen und ist nicht zu unterscheiden</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/implantkrone/09_Impl.jpg" alt="Third slide" />
                              <figcaption class="image-caption">auch die Oberfläche entspricht den Nachbarzähnen und ist nicht zu unterscheiden</figcaption>
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/implantkrone/10_Impl.jpg" alt="Third slide" />
                              <figcaption class="image-caption">auch die Oberfläche entspricht den Nachbarzähnen und ist nicht zu unterscheiden</figcaption>
                            </figure>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="gold" role="tabpanel" aria-labelledby="gold-tab">
                  <div class="container">
                    <h2>Goldteilkronen Inlays</h2>
                    <p>
                      Goldinlays und Teilkronen – eine fast vergessene, aber edle und bewährte Technik.
                      Schon die Inkas stellten Goldfüllungen und Kronen her. Mit der heutigen Gusstechnik
                      und unseren Verarbeitungsmethoden ist eine extreme Präzision zu erreichen, wodurch eine nahezu unbegrenzte Haltbarkeit verbunden ist.
                    </p>
                    <div class="row justify-content-center">
                      <div class="col-10">
                        <el-carousel :indicatorPosition="'none'" :arrow="'always'" :autoplay="false" height="400px">
                          <el-carousel-item class="center-image">
                            <figure>
                              <img src="img/zahnwerkstatt/fallbeispiele/goldteilkrone/Goldteil_Kronen-1.jpeg" alt="First slide" />
                            </figure>
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/goldteilkrone/Goldteil_Kronen-2.jpeg" alt="Second slide" />
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/goldteilkrone/Goldteil_Kronen-3.jpg" alt="Third slide" />
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/goldteilkrone/Goldteil_Kronen-4.jpeg" alt="Third slide" />
                          </el-carousel-item>
                          <el-carousel-item>
                            <img src="img/zahnwerkstatt/fallbeispiele/goldteilkrone/Goldteil_Kronen-5.jpg" alt="Third slide" />
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Carousel, CarouselItem} from "element-ui";

export default {
  name: "ZahnwerkstattAGBPage",
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  },
}
</script>

<style scoped>
.center-image {
  display: flex;
  justify-content: center;
}
.image-caption {
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: -25px;
  color: white;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  //padding: 5px;
  //border: 2px solid #007bff;
  //border-radius: 5px;;
}
</style>
