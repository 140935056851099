import Vue from 'vue';
import Router from 'vue-router';
import Starter from './pages/StarterPage.vue';
import StarterNavbar from './layout/StarterNavbar.vue';
import StarterFooter from './layout/StarterFooter.vue';
import ZahnwerkstattStartPage from "./zahnwerkstatt/pages/ZahnwerkstattStartPage";
import ZahnwerkstattNavbar from "./zahnwerkstatt/ZahnwerkstattNavbar";
import ZahnwerkstattFooter from "./zahnwerkstatt/ZahnwerkstattFooter";
import ZahnwerkstattPatientsPage from "./zahnwerkstatt/pages/ZahnwerkstattPatientsPage";
import ZahnwerkstattAGBPage from "./zahnwerkstatt/pages/ZahnwerkstattAGBPage";
import ZahnwerkstattDataPrivacyPage from "./zahnwerkstatt/pages/ZahnwerkstattDataPrivacyPage";
import ZahnwerkstattImpressumPage from "./zahnwerkstatt/pages/ZahnwerkstattImpressumPage";
import ZahnwerkstattContactPage from "./zahnwerkstatt/pages/ZahnwerkstattContactPage";
import ZahnwerkstattJobsPage from "./zahnwerkstatt/pages/ZahnwerkstattJobsPage";
import ZahnwerkstattDentistsPage from "./zahnwerkstatt/pages/ZahnwerkstattDentistsPage";
import ZahnwerkstattCaseExamplesPage from "./zahnwerkstatt/pages/ZahnwerkstattCaseExamplesPage";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      components: {
        default: ZahnwerkstattStartPage,
        header: ZahnwerkstattNavbar,
        footer: ZahnwerkstattFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/forPatients',
      name: 'PatientInformation',
      components: {
        default: ZahnwerkstattPatientsPage,
        header: ZahnwerkstattNavbar,
        footer: ZahnwerkstattFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/forDentists',
      name: 'DentistInformation',
      components: {
        default: ZahnwerkstattDentistsPage,
        header: ZahnwerkstattNavbar,
        footer: ZahnwerkstattFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/caseExamples',
      name: 'CaseExamples',
      components: {
        default: ZahnwerkstattCaseExamplesPage,
        header: ZahnwerkstattNavbar,
        footer: ZahnwerkstattFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/contact',
      name: 'Contact',
      components: {
        default: ZahnwerkstattContactPage,
        header: ZahnwerkstattNavbar,
        footer: ZahnwerkstattFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/jobs',
      name: 'Jobs',
      components: {
        default: ZahnwerkstattJobsPage,
        header: ZahnwerkstattNavbar,
        footer: ZahnwerkstattFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/impressum',
      name: 'Impressum',
      components: {
        default: ZahnwerkstattImpressumPage,
        header: ZahnwerkstattNavbar,
        footer: ZahnwerkstattFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/dataPrivacy',
      name: 'DataPrivacy',
      components: {
        default: ZahnwerkstattDataPrivacyPage,
        header: ZahnwerkstattNavbar,
        footer: ZahnwerkstattFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/agb',
      name: 'TermsAndConditions',
      components: {
        default: ZahnwerkstattAGBPage,
        header: ZahnwerkstattNavbar,
        footer: ZahnwerkstattFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
