<template>
  <div class="impressumPage">
    <div class="section section-about-us first-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto text-center">
            <h2 class="title">Stellenangebote</h2>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-12">
              <!-- Meister Stellenanzeige -->
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Zahntechnikermeister (m/w/d) gesucht mit Option zur mittelfristigen Laborübernahme</h4>
                  <p class="card-text">
                    Wir sind ein familiengeführtes Dentallabor mit einem engagierten Team von 8 Mitarbeitern
                    direkt am schönen Bodensee (5 Minuten Fußweg!) und suchen ab sofort einen engagierten
                    Zahntechnikermeister mit der Perspektive, unser Labor zu übernehmen.<br/>
                    <br/>
                    Hast Du Lust auf einen zukunftssicheren Arbeitsplatz in einer der wirtschaftlich stärksten
                    Regionen Deutschlands mit hohem Freizeitwert?<br/>
                    <br/>
                    <b>Dein Profil:</b>
                    <ul class="sublist">
                      <li>Umfangreiches Fachwissen, praktische Erfahrung und ein Auge für Ästhetik</li>
                      <li>Hohes Maß an Begeisterung und Eigeninitiative</li>
                      <li>Kommunikationsstärke im Umgang mit Patienten und Kunden</li>
                      <li>Offenheit und Motivation, die Zukunft der Zahntechnik zu gestalten</li>
                      <li>Zielstrebigkeit (Dich wirft so schnell nichts aus der Bahn)</li>
                    </ul>
                    <b>Wir bieten:</b>
                    <ul class="sublist">
                      <li>Ein modern ausgestattetes Labor mit freundlichem Ambiente</li>
                      <li>Möglichkeit zur Mitwirkung und Weiterentwicklung des Betriebs</li>
                      <li>Mittelfristige Perspektive zur Leitung oder Übernahme des Labors</li>
                      <li>Flexible Arbeitszeiten</li>
                      <li>Fahrtkostenzuschuss</li>
                      <li>Weihnachtsgeld</li>
                      <li>Unterstützung bei der Wohnungssuche</li>
                      <li>... weitere Benefits möglich (Weiterbildung, Fitness, vermögenswirksame Leistungen, o.ä.)</li>
                    </ul>
                    Lass uns gemeinsam die Zahnwerkstatt am Bodensee in die nächste Generation führen!<br/>
                    <br/>
                    Wir freuen uns auf Deine Bewerbung!<br/>
                    Gernot Goetz und das Team der Zahnwerkstatt<br/>
                  </p>
                </div>
              </div>

              <!-- Kunststofftechniker Stellenanzeige -->
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Kunststofftechniker*in (m/w/d)</h4>
                  <p class="card-text">
                    Seit 1991 fertigen wir in Unteruhldingen hochwertigen Zahnersatz für unsere anspruchsvollen Zahnärzte.
                    Zum nächstmöglichen Termin suchen wir, ein*e Vollzeit- oder Teilzeitmitarbeiter*in, für die Kunststofftechnik im Dentallabor.<br/>
                    <br/>
                    <b>Dein Aufgabengebiet:</b>
                    <ul class="sublist">
                      <li>Total- /Teilprothesen</li>
                      <li>Interimsprothesen</li>
                      <li>Schienentechnik</li>
                      <li>Prothesen-Reparaturen</li>
                    </ul>
                    <b>Das bringst du mit:</b>
                    <ul class="sublist">
                      <li>abgeschlossene Zahntechnikausbildung oder Quereinsteiger mit 3 Jahren Berufserfahrung</li>
                      <li>Freude am exakten Arbeiten</li>
                      <li>Organisationstalent</li>
                    </ul>
                    Fragen? Dann ruf uns gerne an!<br/>
                    <br/>
                    Wir freuen uns auf Deine Bewerbung!<br/>
                    Gernot Goetz und das Team der Zahnwerkstatt<br/>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZahnwerkstattJobsPage",
};
</script>

<style scoped>

</style>
