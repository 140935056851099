<template>
  <navbar position="fixed" class="navbar-custom" menu-classes="ml-auto">
    <template>
      <router-link v-popover:popover1 class="" to="/">
        <img src="img/zahnwerkstatt/zahnwerkstatt-logo.png" class="custom-logo" alt="Brand Logo" />
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <a
            class="nav-link"
            href="#/"
        >
          <p>Home</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="#/forPatients"
        >
          <p>Für Patienten</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="#/forDentists"
        >
          <p>Für Zahnärzte</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="#/caseExamples"
        >
          <p>Fallbeispiele</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="#/contact"
        >
          <p>Kontakt</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="#/jobs"
        >
          <p>Stellenangebote</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, NavbarToggleButton, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';

export default {
  name: 'main-navbar',
  components: {
    Navbar,
    [Popover.name]: Popover
  }
};
</script>

<style scoped>
.navbar-custom {
  font-size: 1.3rem;
}
.custom-logo {
    width:200px; /* you can use % */
    height: auto;
}
</style>
