<template>
  <div id="app">
    <router-view name="header" />
    <div class="wrapper sticky-footer">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
export default {};
</script>
<style>
.first-section {
  padding-top: 100px!important;
}
.stretch-image {
  width: 100%;
}
.sticky-footer {
  margin-top: 0vh;
  margin-bottom: 6vh;
}
.starter-page {
  min-height: calc(100vh - 95px);
}
.mt-c1 {
  margin-top: 10rem;
}
.mt-c2 {
  margin-top: 20rem;
}

@media (min-width: 100px) {
  .mt-c3 {
    margin-top: 40rem;
  }
}

@media (min-width: 770px) {
  .mt-c3 {
    margin-top: 50rem;
  }
}

@media (min-width: 990px) {
  .mt-c3 {
    margin-top: 35rem;
  }
}

@media (min-width: 990px) {
  .mt-c3 {
    margin-top: 35rem;
  }
}

@media (min-width: 1200px) {
  .mt-c3 {
    margin-top: 30rem;
  }
}

.mt-c1n {
  margin-top: -10rem;
}
</style>
