<template>
  <div class="starter-page">
    <div class="page-header page-header-small">
      <parallax
          class="page-header-image"
          style="background-image: url('img/banner_test.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Schöne Zähne - ein gutes Gefühl</h1>
          <h2 class="title">IHR DENTALLABOR am Bodensee</h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'starter'
};
</script>
<style>
.starter-page {
  min-height: calc(100vh - 95px);
}
</style>
