<template>
  <div class="impressumPage">
    <div class="section first-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <h2 class="title">Kontakt</h2>
            <p>
              Die Zahnwerkstatt Gernot Goetz GmbH<br/>
              Ehbachstraße 11<br/>
              D-88690 Unteruhldingen<br/>
              Telefon: 0 75 56 / 55 97<br/>
              Telefax: 0 75 56 / 56 41<br/>
              http: www.die-zahnwerkstatt.de<br/>
              eMail: info&#64;die-zahnwerkstatt.de<br/>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto mb-1 h-335">
            <iframe
                width="100%"
                height="400"
                style="border:0"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC4gDDEDa-CCO3pw5Hh4AUEYk-txyF0TEI
    &q=Zahnwerkstatt+Gernot+Goetz+GmbH">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZahnwerkstattAGBPage",
}
</script>

<style scoped>
.h-335 {
  height: 335px;
  position: relative;
  background-position: center center;
  background-size: cover;
  box-shadow: $box-shadow-raised;
  border-radius: .25rem;
}
</style>
