<template>
  <navbar position="fixed" type="primary" menu-classes="ml-auto">
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/presentation">
        Die Zahnwerkstatt Gernot Goetz Gmbh
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <a
            class="nav-link"
            href="https://www.creative-tim.com/product/vue-now-ui-kit"
            target="_blank"
        >
          <p>Home</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="https://www.creative-tim.com/product/vue-now-ui-kit"
            target="_blank"
        >
          <p>Für Patienten</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="https://www.creative-tim.com/product/vue-now-ui-kit"
            target="_blank"
        >
          <p>Für Zahnärzte</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="https://www.creative-tim.com/product/vue-now-ui-kit"
            target="_blank"
        >
          <p>Wir über uns</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="https://www.creative-tim.com/product/vue-now-ui-kit"
            target="_blank"
        >
          <p>Fallbeispiele</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="https://www.creative-tim.com/product/vue-now-ui-kit"
            target="_blank"
        >
          <p>Kontakt</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="https://www.creative-tim.com/product/vue-now-ui-kit"
            target="_blank"
        >
          <p>Stellenangebote</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            href="https://www.creative-tim.com/product/vue-now-ui-kit"
            target="_blank"
        >
          <p>Links</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, NavbarToggleButton, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';

export default {
  name: 'main-navbar',
  components: {
    Navbar,
    [Popover.name]: Popover
  }
};
</script>

<style scoped></style>
