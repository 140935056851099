<template>
  <div class="impressumPage">
    <div class="section first-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <h2 class="title">Impressum</h2>
            <p>Angaben gemäß § 5 TMG:<br/>
              Zahnwerkstatt Gernot Goetz GmbH<br/>
              Ehbachstraße 11<br/>
              88690 Uhldingen- Mühlhofen<br/>
              <strong>Vertreten durch:</strong><br/>
              Gernot Goetz<br/>
              <strong>Kontakt:</strong><br/>
              Telefon: +49 (0) 7556 5597<br/>
              Telefax: +49 (0) 75565641<br/>
              E-Mail: info@die-zahnwerkstatt.de<br/>
              Eintragung im Handelsregister<br/>
              Registergericht: Amtsgericht Überlingen<br/>
              Registernummer: HRB896<br/>
              Aufsichtsbehörde:<br/>
              Handwerkskammer Ulm<br/>
              Olgastraße 72<br/>
              89073 Ulm<br/>
              <strong>Disclaimer</strong><br/>
              Inhalt und Werke dieser Website sind urheberrechtlich geschützt.
              Trotz höchster Sorgfalt kann nicht für die Richtigkeit der wiedergegebenen Informationen,
              oder die permanente technische Erreichbarkeit, garantiert werden.
              Es wird keine Haftung für den Inhalt von extern verlinkten Websites übernommen.
              Auf deren Inhalte haben wir keinen Einfluss und distanzieren uns ausdrücklich.
              Sollten Sie dennoch etwas an unseren Seiten zu beanstanden haben,
              bitten wir um einen einfachen, entsprechenden Hinweis,
              damit wir die Inhalte schnellstmöglich entfernen können.<br/>
              Quelle: Impressum-Generator von anwalt.de</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZahnwerkstattImpressum",
}
</script>

<style scoped>

</style>
