<template>
  <div class="starter-page first-section">
    <div class="page-header page-header-small">
      <parallax
          class="page-header-image"
          style="background-image: url('img/zahnwerkstatt/startpage/startpage_banner.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container banner-text">
          <h1 class="title">Schöne Zähne - ein gutes Gefühl</h1>
          <h2 class="title">IHR DENTALLABOR am Bodensee</h2>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Wir über uns</h2>
            <h5 class="description">
              In unseren modernen, lichtdurchfluteten Räumen macht das Arbeiten Spaß.
              Auf 180 Quadratmeter haben wir die Möglichkeit, modernste Maschinen mit optimalen Arbeitsbedingungen zu kombinieren.
              Unsere Besucher können im schönen Empfangsraum Platz nehmen und gelangen von dort auch zum separaten Patientenbereich.
              Hier können wir mit unseren Patienten, in Ruhe, individuelle Abstimmungen der Arbeiten vornehmen.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                  class="image-container image-left"
                  style="background-image: url('img/zahnwerkstatt/startpage/startpage_image1.jpg')"
              >
                <!-- First image on the left side -->
              </div>
              <!-- Second image on the left side of the article -->
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                  class="image-container image-right"
                  style="background-image: url('img/zahnwerkstatt/startpage/starter_02.jpg')"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section mt-c1n" id="carousel">
      <div class="container">
        <h2 class="title text-center">Handwerk</h2>
        <div class="row justify-content-center">
          <div class="col-12">
            <el-carousel height="500px">
              <el-carousel-item>
                <img class="d-block" src="img/zahnwerkstatt/startpage/start_meisterbrief.jpg" alt="Fourth slide" />
              </el-carousel-item>
              <el-carousel-item>
                <img class="d-block" src="img/zahnwerkstatt/startpage/startpage_craft_1.jpg" alt="First slide" />
              </el-carousel-item>
              <el-carousel-item>
                <img class="d-block" src="img/zahnwerkstatt/startpage/startpage_craft_2.jpg" alt="Second slide" />
              </el-carousel-item>
              <el-carousel-item>
                <img class="d-block" src="img/zahnwerkstatt/startpage/startpage_craft_3.jpg" alt="Third slide" />
              </el-carousel-item>
              <el-carousel-item>
                <img class="d-block" src="img/zahnwerkstatt/startpage/startpage_craft_6.jpg" alt="Fifth slide" />
              </el-carousel-item>
              <el-carousel-item>
                <img class="d-block stretch-image" src="img/zahnwerkstatt/startpage/startpage_fire.jpg" alt="Fifth slide" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, CarouselItem } from 'element-ui';
import Card from "../../components/Cards/Card";

export default {
  name: 'starter',
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  },
};
</script>
<style scoped>

.center-image {
  display: flex;
  justify-content: center;
}

@media (max-width: 375px) {
  .banner-text {
    padding-top: 30px;
    font-size: 11px;
  }
}

@media (max-width: 770px) {
  .banner-text {
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  .banner-text {
    font-size: 10px;
  }
}

</style>
